<template>
    <v-form ref="loginForm" v-model="isValid">
        <p class="text-caption">รหัสพนักงาน</p>
        <v-text-field 
            color="yellow darken-3"
            required 
            v-model="form.customerId" 
            dense 
            :rules="customerIdRules"
            placeholder="โปรดใส่รหัสพนักงาน">
        </v-text-field>
        <p class="text-caption mt-1">ชื่อ - นามสกุล</p>
        <v-text-field 
            color="yellow darken-3"
            required 
            v-model="form.name" 
            dense
            :rules="nameRules"
            placeholder="ระบุชื่อผู้ใช้งาน">
        </v-text-field>
        <p class="text-caption mt-1">เบอร์โทรศัพท์</p>
        <v-text-field 
            color="yellow darken-3"
            required 
            v-model="form.mobile" 
            dense 
            :rules="mobileRules"
            placeholder="ex. 0394992346">
        </v-text-field>
        <p class="text-caption mt-1">อีเมล</p>
        <v-text-field 
            color="yellow darken-3"
            :disabled="id != null"
            required 
            v-model="form.email" 
            dense 
            :rules="emailRules"
            placeholder="ex. movevai@gmail.com">
        </v-text-field>
        <p class="text-subtitle-2 font-weight-bold mt-1">กรุณาระบุ Role ผู้ใช้งาน</p>
        <v-radio-group
        row
        v-model="form.role"
        >
            <v-radio
                color="yellow darken-3"
                label="Staff"
                value="staff"
            ></v-radio>
            <v-radio
                color="yellow darken-3"
                label="Senior Staff"
                value="sr_staff"
            ></v-radio>
            <v-radio
                color="yellow darken-3"
                label="Admin"
                value="admin"
            ></v-radio>
            <v-radio
                color="yellow darken-3"
                label="Approval"
                value="approval"
            ></v-radio>
            <v-radio
                color="yellow darken-3"
                label="Account"
                value="account"
            ></v-radio>
            <v-radio
                color="yellow darken-3"
                label="Messenger"
                value="messenger"
            ></v-radio>
        </v-radio-group>

        <div class="mb-3" v-if="['account', 'admin'].indexOf(form.role) == -1">
            <p class="text-subtitle-2 font-weight-bold mt-1">แบบลายเซ็นเจ้าหน้าที่</p>
            <v-btn 
                color="primary" 
                outlined 
                dark 
                @click="$refs.uploader.click()"
                :disabled="file != null || signatureFileDownload != null"
            >
                <v-icon>mdi-file-plus</v-icon> เพิ่มไฟล์
            </v-btn>

        <!-- Not upload yet -->
            <v-chip
                v-if="signatureFileName && signatureFileDownload == null"
                class="ml-4 ma-1"
                close
                color="gray"
                outlined
                @click:close="removeUploadFile"
            >
                {{ signatureFileName }}
            </v-chip>

        <!-- Already Upload -->
        <v-chip
            v-if="signatureFileDownload != null"
            class="ml-4 ma-1"
            close
            color="grey"
            outlined
            link
            @click="downloadSignatureFile"
            @click:close="removeUploadFile"
        >
            {{ signatureFileName }}
        </v-chip>
        <v-icon v-if="signatureFileDownload != null" color="yellow darken-3"> mdi-check </v-icon>
            <input 
                ref="uploader" 
                class="d-none" 
                type="file" 
                @change="onFileChanged"
            >
        </div>

        <div v-if="form.role == 'staff'">
            <p class="text-subtitle-2 font-weight-bold mt-1">กำหนดสิทธิ์การเข้าถึง</p>
                <v-checkbox
                    v-for="o of permissionOptions" :key="o.value"
                    color="yellow darken-3"
                    class="my-0 py-0"
                    v-model="form.permissions"
                    :label="o.label"
                    :value="o.value"
                    dense
                ></v-checkbox>
        </div>
    <alert-error :text="errorMessage" ref="alertError"></alert-error>
    <alert-success :text="successMessage" :redirectTo="goTo" ref="alertSuccess"></alert-success>
    </v-form>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import AlertError from './AlertError.vue';
import AlertSuccess from './AlertSuccess.vue';
import { getUserById } from '../models/user';
import fd from 'js-file-download';
import Text from '../utils/text-mapping';

export default {
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        form: {
            role: 'staff',
            permissions: [],
            customerId: '',
            name: '',
            mobile: '',
            email: ''
        },
        customerIdRules: [
            v => !!v || 'โปรดกรอกชื่อรหัสพนักงาน'
        ],
        nameRules: [
            v => !!v || 'โปรดกรอกชื่อผู้ใช้งาน'
        ],
        mobileRules: [
            v => !!v || 'โปรดกรอกเบอร์โทรศัพท์',
            v => 10 == v.length || 'ตัวอย่างรูปแบบเบอร์โทร ex. 0394992346',
            v => {
                const pattern = /^\d+$/;
                const result = pattern.test(v);
                return result || 'ตัวอย่างรูปแบบเบอร์โทร ex. 0394992346';
            }
        ],
        emailRules: [
            v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง';
            },
            v => !!v || 'โปรดกรอกอีเมล'
        ],
        isValid: true,
        errorMessage: '',
        successMessage: '',
        goTo: {
            name: 'Users'
        },
        signatureFileName: '',
        file: null,
        isDeletesignatureFile: false,
        signatureFileDownload: null,
        permissionOptions: Text.permissionOptions()
    }),
    methods: {
        async submit(){
            this.errorMessage = '';
            this.successMessage = '';
            this.$refs.loginForm.validate();
            if(this.id){ // edit
                if(this.isValid){
                    let loader = this.$loading.show();
                    try{
                        await axios.patch(process.env.VUE_APP_BASE_URL + '/users/' + this.id, this.form);
                        if(this.isDeletesignatureFile){ // delete it
                            await axios.delete(process.env.VUE_APP_BASE_URL + `/users/${this.id}/signature`);
                        }
                        if(this.file){ // upload file
                            const formData = new FormData();
                            formData.append('image', this.file);
                            await axios.post(process.env.VUE_APP_BASE_URL + `/users/${this.id}/signature`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                        }
                        this.successMessage = 'แก้ไขผู้ใช้งานสำเร็จแล้ว';
                        this.goTo = { name: 'UsersDetails', params: { id: this.id } };
                        this.$refs.alertSuccess.show();
                    }catch(error){
                        this.errorMessage = error.response?.data[0]?.msg || error.message;
                        this.$refs.alertError.show();
                    }finally{
                        loader.hide();
                    }
                }
            }else{ // create
                if(this.isValid){
                    let loader = this.$loading.show();
                    try{
                        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/users', this.form);
                        const id = response.data.user.id;
                        if(this.file){ // upload file
                            const formData = new FormData();
                            formData.append('image', this.file);
                            await axios.post(process.env.VUE_APP_BASE_URL + `/users/${id}/signature`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                        }
                        this.successMessage = 'สร้างผู้ใช้งานสำเร็จ ระบบส่งอีเมลเพื่อยืนยันไปยังที่ระบุเรียบร้อยแล้ว';
                        this.goTo = { name: 'Users' };
                        this.$refs.alertSuccess.show();
                    }catch(error){
                        this.errorMessage = error.response?.data[0]?.msg || error.message;
                        this.$refs.alertError.show();
                    }finally{
                        loader.hide();
                    }
                }
            }
        },
        async getUser(){
            if(this.id){ // get data
                let loader = this.$loading.show();
                try{
                    const user = await getUserById(this.id);
                    this.form = {
                        role: user.role,
                        permissions: user.permissions,
                        customerId: user.id,
                        name: user.name,
                        mobile: user.mobile,
                        email: user.email
                    };
                    if(user.signature){
                        this.signatureFileDownload = user.signature;
                        this.signatureFileName = 'กดเพื่อดาวโหลดลายเซ็นต์';
                    }
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.message;
                }finally{
                    loader.hide();
                }
            }
        },
        onFileChanged(e){
            this.file = e.target.files[0];
            this.signatureFileName = this.file.name;
            this.isDeletesignatureFile = false;
        },
        removeUploadFile(){
            this.signatureFileName = '';
            this.file = null;
            this.isDeletesignatureFile = true;
            this.signatureFileDownload = null;
        },
        downloadSignatureFile(){
            axios.get(this.signatureFileDownload, { responseType: 'blob' })
            .then(response => {
                fd(response.data, 'signature_' + this.id, response.headers['content-type']);
            });
        }
    },
    mounted(){
            this.getUser();
    },
    watch: {
        id(){
            this.getUser();
        }
    },
    components: {
        AlertError,
        AlertSuccess
    }
};
</script>